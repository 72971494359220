import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Icon,
  IconButton,
  Button,
  CircularProgress,
  Snackbar,
  Backdrop,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
// import "./signup.css";
import { difference } from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { auth, db, fieldValue } from "../../constants/firestore";
import { useSelector, useDispatch } from "react-redux";
import { update, userData } from "../../redux/reducers/user/slice";
export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddCategory(props) {
  const history = useHistory();
  const user = useSelector(userData);
  const [values, setValues] = useState({
    full_name: "",
    // last_name: "",
    phone_number: "",
    email: "",
    roles: "",
    password: "",
    confirm: "",
    is_active: "yes",
    id: "",
  });
  const [title, settitle] = useState("");
  const [titleError, settitleError] = useState({
    status: false,
    message: "",
  });
  const [categoryError, setcategoryError] = useState({
    status: false,
    message: "",
  });
  const [status, setstatus] = useState("yes");
  const reset = () => {
    settitle("");
    setcategories([]);
    setstatus("yes");
  };

  const [loading, setloading] = useState(false);
  const [openSnack, setOpenSnack] = useState({
    show: false,
    message: "",
    severity: "",
  });

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.close();
    reset();
    setOpenSnack({
      message: "",
      show: false,
      severity: "",
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const errors = () => {
    settitleError({
      message:
        title.trim().length < 2
          ? "Title must be 2 characters or more"
          : titleStatus.status
          ? "Title is taken"
          : "",
      status: title.trim().length < 2 || titleStatus.status,
    });
    setcategoryError({
      message:
        categories.length === 0 ? "At least a category must be selected" : "",
      status: categories.length === 0,
    });
  };

  const createCategory = () => {
    setloading(true);
    let catRef = db.collection("categories").doc();

    catRef
      .set({
        id: catRef.id,
        title: title,
        type: categories,
        created_at: new Date().getTime(),
        is_active: status === "yes" ? true : false,
        added_by: user.user.id,
      })
      .then((docRef) => {
        setloading(false);
        // props.close();
        // reset();

        setOpenSnack({
          message: "Category created successfully",
          show: true,
          severity: "success",
        });
        // console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        setloading(false);
        setOpenSnack({
          message: error,
          show: true,
          severity: "error",
        });
        console.error("Error adding category: ", error);
      });
  };
  const updateCategory = () => {
    let isActive = status === "yes" ? true : false;
    let dif = difference(props.category.type, categories);
    if (
      title !== props.category.title ||
      isActive !== props.category.is_active ||
      dif.length > 0
    ) {
      let id = user.user.id;
      let catRef = db.collection("categories").doc(props.category.id);
      setloading(true);
      catRef
        .update({
          title: title,
          type: categories,
          is_active: isActive,
          updated: fieldValue.arrayUnion({
            on: new Date().getTime(),
            by: id,
          }),
        })
        .then((docRef) => {
          setloading(false);
          setOpenSnack({
            message: "Category updated successfully",
            show: true,
            severity: "success",
          });
          // setTimeout(() => {
          //   props.close();
          //   reset();
          // }, 2400);

          // console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          setloading(false);
          setOpenSnack({
            message: error,
            show: true,
            severity: "error",
          });
          console.error("Error updating category: ", error);
        });
    } else {
      setOpenSnack({
        message: "No Changes made yet, make changes to update category",
        show: true,
        severity: "error",
      });
    }
  };
  const [categories, setcategories] = useState([]);
  useEffect(() => {
    console.log(props.user, "userDataval");
    if (props.category !== null) {
      settitle(props.category.title);
      setcategories(props.category.type);
      setstatus(props.category.is_active ? "yes" : "no");
    }
    if (props.user !== null) {
      let userDataVal = props.user;
      // setValues({
      //   full_name: userDataVal.full_name,
      //   is_active: userDataVal.is_active ? "yes" : "no",
      //   email: userDataVal.email,
      //   roles: userDataVal.roles.includes("ADMIN")
      //     ? "ADMIN"
      //     : userDataVal.roles.includes("OWNER")
      //     ? "OWNER"
      //     : "USER",
      //   phone_number: userDataVal.phone_number,
      // });
    }
  }, [props.category]);
  useEffect(() => {
    console.log(categories);
    // return () => {
    //   cleanup
    // }
  }, [categories]);
  const [, setstate] = useState({});
  const [titleStatus, settitleStatus] = useState({
    show: false,
    status: false,
  });

  const checkCategoryTitle = (tle) => {
    db.collection("categories")
      .where("title", "==", tle)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          settitleStatus({
            show: true,
            status: false,
          });
          settitleError({
            message: "",
            status: false,
          });
        } else {
          settitleStatus({
            show: true,
            status: true,
          });
          settitleError({
            message: "Title is taken",
            status: true,
          });
        }
      });
  };
  const dispatch = useDispatch();
  return (
    <Backdrop
      style={{
        zIndex: 1202,
        display: "flex",
        flexDirection: "column",
      }}
      open={props.modal.show}
      // onClick={handleCloseModal}
    >
      <Snackbar
        open={openSnack.show}
        autoHideDuration={2500}
        onClose={handleSnackClose}
        style={{ zIndex: 1210 }}
      >
        <Alert onClose={handleSnackClose} severity={openSnack.severity}>
          {openSnack.message}
        </Alert>
      </Snackbar>
      <div className="add-user-container">
        <div style={{ alignSelf: "flex-end" }}>
          <IconButton
            onClick={() => {
              props.close();
              reset();
              settitleStatus({
                show: false,
                status: false,
              });
              settitleError({
                message: "",
                status: false,
              });
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
        <h3 style={{ marginTop: 0 }}>
          {props.modal.for === "edit" ? "EDIT CATEGORY" : "ADD CATEGORY"}
        </h3>
        <div className="sign-up-container">
          <div className="sign-up-container-two">
            <div style={{ marginTop: 5, marginBottom: 20 }}>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={titleError.status}
                    helperText={titleError.message}
                    value={title}
                    InputProps={{
                      autoComplete: "off",
                      endAdornment: props.modal.for !== "edit" &&
                        titleStatus.show && (
                          <InputAdornment position="end">
                            <Icon
                              style={{
                                color: titleStatus.status ? "red" : "green",
                              }}
                            >
                              {titleStatus.status ? "cancel" : "check_circle"}
                            </Icon>
                          </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                      if (event.target.value.length > 1) {
                        checkCategoryTitle(event.target.value);
                      }

                      settitle(event.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 15,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Category</span>
                  <div style={{ display: "flex" }}>
                    {["SHOP", "RESTAURANT", "PRODUCT"].map((item, index) => {
                      return (
                        <div>
                          <Checkbox
                            key={item}
                            value={item}
                            checked={categories.includes(item)}
                            onChange={(event) => {
                              setcategories((cats) => {
                                if (cats.includes(item)) {
                                  return cats.filter((cat) => cat !== item);
                                } else {
                                  // console.log("i console");
                                  cats.push(item);
                                  // console.log(cats, "i also console");
                                  return cats;
                                }
                              });
                              setstate({});
                              // setcategories({
                              //   ...categories,
                              //   [item.toLowerCase()]: event.target.checked,
                              // });
                              // console.log(categorie)
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                          <span>{item}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <FormControl
                  component="fieldset"
                  style={{ marginTop: "1.5em" }}
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    aria-label="status"
                    name="Status"
                    value={status}
                    style={{ display: "flex", flexDirection: "row" }}
                    onChange={(event) => {
                      setstatus(event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="InActive"
                    />
                  </RadioGroup>
                </FormControl>
              </form>
              <div className="sign-up-button-container">
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={loading}
                  onMouseDown={handleMouseDownPassword}
                  onClick={() => {
                    if (props.modal.for === "edit") {
                      if (
                        title.length > 1 &&
                        categories.length > 0 &&
                        !titleStatus.status
                      ) {
                        // console.log("updateUser");

                        updateCategory();
                        errors();
                        // console.log("i ran");
                      } else {
                        errors();
                      }
                    } else {
                      if (
                        title.length > 1 &&
                        categories.length > 0 &&
                        !titleStatus.status
                      ) {
                        setloading(true);
                        createCategory();
                        errors();
                        // console.log("i ran");
                      } else {
                        errors();
                      }
                    }
                  }}
                >
                  {!loading ? (
                    props.modal.for === "edit" ? (
                      "UPDATE"
                    ) : (
                      "ADD"
                    )
                  ) : (
                    <CircularProgress
                      size={24}
                      color={"secondary"}
                      // style={{ color: "white" }}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}
