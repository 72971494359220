import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  CircularProgress,
  FormHelperText,
  Snackbar,
  Backdrop,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Chip,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
// import "./signup.css";
import { difference } from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { auth, db, fieldValue } from "../../constants/firestore";
import { useSelector, useDispatch } from "react-redux";
import { update, userData } from "../../redux/reducers/user/slice";
import { categories } from "../../redux/reducers/categories/slice";
import { campus } from "../../redux/reducers/campus/slice";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import { shops } from "../../redux/reducers/shops/slice";

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddShop(props) {
  const history = useHistory();
  const user = useSelector(userData);
  const categoriesList = useSelector(categories);
  const campusList = useSelector(campus);
  const [campusData, setcampusData] = useState([]);
  const [categoriesData, setcategoriesData] = useState([]);
  const shopsState = useSelector(shops);
  const [owner, setowner] = useState({
    id: "",
  });

  useEffect(() => {
    console.log(shopsState, "owners page");
    if (shopsState.shops !== null) {
      setowner({ ...shopsState.owner });
      console.log(shopsState.owner, "owner");
    }
  }, [shopsState]);
  useEffect(() => {
    console.log(campusList, "add campus");
    if (campusList.campus !== null) {
      console.log("this runs campus");
      setcampusData(campusList.campus);
    }
  }, [campusList]);
  useEffect(() => {
    console.log(categoriesList, "add shop categories");
    if (categoriesList.categories !== null) {
      console.log("this runs");
      setcategoriesData(categoriesList.categories);
    }
  }, [categoriesList]);
  const [modal, setmodal] = useState({ show: false, for: "" });
  const [values, setValues] = useState({
    title: "",
    phone_number: "",
    description: "",
    is_active: "yes",
    phone_number: "",
    location: {
      coords: {
        lat: null,
        lng: null,
      },
      name: "",
      viewport: {
        northeast: {
          lat: null,
          lng: null,
        },
        southwest: {
          lat: null,
          lng: null,
        },
      },
    },
    added_by: "",
    campus: "",
    category: "",
    id: "",
    owners: [],
  });
  const [type, settype] = useState("SHOP");
  const reset = () => {
    setValues({
      title: "",
      phone_number: "",
      description: "",
      is_active: "yes",
      phone_number: "",
      location: {
        coords: {
          lat: null,
          lng: null,
        },
        name: "",
        viewport: {
          northeast: {
            lat: null,
            lng: null,
          },
          southwest: {
            lat: null,
            lng: null,
          },
        },
      },
      added_by: "",
      campus: "",
      category: "",
      id: "",
      owners: [],
    });
  };
  const [titleError, settitleError] = useState({
    message: null,
    status: false,
  });
  const [locationError, setlocationError] = useState({
    message: null,
    status: false,
  });
  const [phone_numberError, setphone_numberError] = useState({
    message: null,
    status: false,
  });
  const [descriptionError, setdescriptionError] = useState({
    message: null,
    status: false,
  });
  const [categoryError, setcategoryError] = useState({
    message: null,
    status: false,
  });
  const [campusError, setcampusError] = useState({
    message: null,
    status: false,
  });

  const [loading, setloading] = useState(false);
  const [openSnack, setOpenSnack] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [usernameStatus, setusernameStatus] = useState({
    status: false,
    show: false,
  });
  const [emailStatus, setemailStatus] = useState({
    status: false,
    show: false,
  });
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack({
      message: "",
      show: false,
      severity: "success",
    });
  };
  const [visibility, setVisibility] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleCloseModal = () => {
    setmodal({ show: false, for: "" });
  };
  const handleModalToggle = (f) => {
    setmodal({
      show: !modal.show,
      for: f,
    });
  };
  const handleChange = (prop) => (event) => {
    // if (prop === "email" && props.user === null && props.modal.for !== "edit") {
    //   checkforEmail(event.target.value);
    // }

    setValues({ ...values, [prop]: event.target.value });

    // console.log(values);
    // errors();
  };

  const handleClickShowPassword = (prop, state) => {
    setVisibility({ ...visibility, [prop]: state });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const errors = () => {
    settitleError({
      status: values.title.length < 2,
      message:
        values.title.length < 2 ? "Title must be 2 or more characters" : "",
    });
    setphone_numberError({
      status: values.phone_number.length !== 10,
      message:
        values.phone_number.length !== 10
          ? "Phone number must be 10 characters"
          : "",
    });
    setcategoryError({
      message: values.category.length === 0 ? "Choose a category" : "",
      status: values.category.length === 0,
    });
    setcampusError({
      message: values.campus.length === 0 ? "Choose a campus" : "",
      status: values.campus.length === 0,
    });
    setdescriptionError({
      status: values.description.length < 5,
      message:
        values.description.length < 5
          ? "Description must be 5 or more characters"
          : "",
    });
    setlocationError({
      message:
        values.location.name.length === 0 ? "Provide location of shop" : "",
      status: values.location.name.length === 0,
    });
  };
  // const checkforEmail = (email) => {
  //   db.collection("users")
  //     .where("email", "==", email)
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot.empty) {
  //         setemailStatus({
  //           show: true,
  //           status: false,
  //         });
  //         setemailError({
  //           message: "",
  //           status: false,
  //         });
  //       } else {
  //         setemailStatus({
  //           show: true,
  //           status: true,
  //         });
  //         setemailError({
  //           message: "Email is taken",
  //           status: true,
  //         });
  //       }
  //     });
  // };
  useEffect(() => {
    if (props.editData !== null) {
      setValues({
        ...props.editData,
        is_active: props.editData.is_active ? "yes" : "no",
      });
    }
  }, [props.editData]);
  const createShop = () => {
    setloading(true);

    let shopRef = db.collection("shops").doc();

    shopRef
      .set({
        title: values.title,
        phone_number: values.phone_number,
        description: values.description,
        is_active: values.is_active === "yes" ? true : false,
        location: values.location,
        added_by: user.user.id,
        campus: values.campus,
        category: values.category,
        id: shopRef.id,
        owners: fieldValue.arrayUnion(owner.id),
        ownersInfo: fieldValue.arrayUnion({
          full_name: owner.full_name,
          phone_number: owner.phone_number,
          id: owner.id,
        }),
        type: type,
      })
      .then((docRef) => {
        setloading(false);
        setTimeout(() => {
          props.close();
        }, 2500);
        reset();
        setOpenSnack({
          message: "Shop created successfully",
          show: true,
          severity: "success",
        });
        // console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        setloading(false);
        setOpenSnack({
          message: error,
          show: true,
          severity: "error",
        });
        console.error("Error adding Shop: ", error);
      });
    // ...
  };
  const updateShop = () => {
    let isActive = values.is_active === "yes" ? true : false;
    // let dif = difference(props.user.roles, userRole);
    if (
      values.title !== props.editData.title ||
      values.phone_number !== props.user.phone_number ||
      isActive !== props.user.is_active
      // || dif.length > 0
    ) {
      let id = user.user.id;
      let shopRef = db.collection("shops").doc(props.user.id);
      setloading(true);
      shopRef
        .update({
          title: values.title,
          phone_number: values.phone_number,
          description: values.description,
          is_active: values.is_active === "yes" ? true : false,
          phone_number: values.phone_number,
          location: values.location,
          added_by: user.user.id,
          campus: values.campus,
          category: values.category,
          id: shopRef.id,
          owners: fieldValue.arrayUnion(owner.id),
          type: type,
          updated: fieldValue.arrayUnion({
            on: new Date().getTime(),
            by: id,
          }),
        })
        .then((docRef) => {
          setloading(false);
          setOpenSnack({
            message: "Account updated successfully",
            show: true,
            severity: "success",
          });
          props.close();
          reset();
          // console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          setloading(false);
          setOpenSnack({
            message: error,
            show: true,
            severity: "error",
          });
          console.error("Error updating document: ", error);
        });
    } else {
      setOpenSnack({
        message: "No Changes made yet, make changes to update user",
        show: true,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    console.log(props.user, "userDataval");
  }, [props.user]);
  const dispatch = useDispatch();
  return (
    <Backdrop
      style={{
        zIndex: 1202,
        display: "flex",
        flexDirection: "column",
      }}
      open={props.modal.show}
      // onClick={handleCloseModal}
    >
      <Snackbar
        open={openSnack.show}
        autoHideDuration={2500}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity={openSnack.severity}>
          {openSnack.message}
        </Alert>
      </Snackbar>
      <div className="add-user-container" style={{ paddingTop: 80 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
          }}
        >
          <div
            style={{ justifySelf: "flex-start", display: "flex", gap: "1em" }}
          >
            {["SHOP", "RESTAURANT"].map((item, index) => (
              <Chip
                style={{ backgroundColor: item === type ? "red" : "grey" }}
                label={item}
                onClick={() => {
                  settype(item);
                }}
              />
            ))}
          </div>
          <IconButton
            style={{ alignSelf: "flex-end", justifySelf: "flex-end" }}
            onClick={() => {
              props.close();
              // setemailStatus({
              //   show: false,
              //   status: false,
              // });
              // setemailError({
              //   message: "",
              //   status: false,
              // });
              reset();
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
        <h3 style={{ marginTop: 0 }}>
          {props.modal.for === "edit" ? "EDIT" : "ADD SHOP"}
        </h3>
        <div className="sign-up-container">
          <div className="sign-up-container-two">
            <div style={{ marginTop: 5, marginBottom: 20 }}>
              <form className="sign-up-form" noValidate autoComplete="off">
                <div>
                  <TextField
                    // disabled={props.modal.for === "edit" ? true : false}
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    autoComplete="off"
                    error={titleError.status}
                    helperText={titleError.message}
                    value={values.title}
                    onChange={handleChange("title")}
                    // InputProps={{
                    //   autoComplete: "off",
                    //   endAdornment: props.modal.for !== "edit" &&
                    //     emailStatus.show && (
                    //       <InputAdornment position="end">
                    //         <Icon
                    //           style={{
                    //             color: emailStatus.status ? "red" : "green",
                    //           }}
                    //         >
                    //           {emailStatus.status ? "cancel" : "check_circle"}
                    //         </Icon>
                    //       </InputAdornment>
                    //     ),
                    // }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    inputProps={{
                      maxLength: 10,
                    }}
                    variant="outlined"
                    error={phone_numberError.status}
                    helperText={phone_numberError.message}
                    value={values.phone_number}
                    onChange={handleChange("phone_number")}
                  />
                </div>

                <TextField
                  id="outlined-basic"
                  label="Shop Description"
                  variant="outlined"
                  multiline
                  rows={4}
                  error={descriptionError.status}
                  value={values.description}
                  helperText={descriptionError.message}
                  onChange={handleChange("description")}
                />
                <div>
                  <FormControl
                    variant="outlined"
                    style={{ width: "47%", marginTop: "1em" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.category}
                      error={categoryError.status}
                      onChange={handleChange("category")}
                      label="Role"
                      style={{ zIndex: 1203 }}
                    >
                      {categoriesData.map((item, index) => {
                        return (
                          <MenuItem key={item.title} value={item.title}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                    <MenuItem value={"OWNER"}>OWNER</MenuItem>
                    <MenuItem value={"USER"}>USER</MenuItem> */}
                    </Select>
                    {categoryError.status && (
                      <FormHelperText error={categoryError.status}>
                        {categoryError.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ width: "47%", marginTop: "1em" }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Campus
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.campus}
                      error={campusError.status}
                      onChange={handleChange("campus")}
                      label="Campus"
                      style={{ zIndex: 1203 }}
                    >
                      {campusData.map((item, index) => {
                        return (
                          <MenuItem
                            key={item.short_name}
                            value={item.short_name}
                          >
                            {item.short_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {campusError.status && (
                      <FormHelperText error={campusError.status}>
                        {campusError.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                {/* <div> */}
                {/* <Autocomplete
                  apiKey={"AIzaSyBudBqSdd0Plu0MAgdLhXMuYoOUM0Jl-40"}
                  onPlaceSelected={(place, inputRef, autocomplete) => {
                    console.log(autocomplete);
                  }}
                /> */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <GooglePlacesAutocomplete
                    selectProps={{
                      onChange: (value) => {
                        // errors();
                        console.log(value.value.place_id, "selected place");
                        let place_id = value.value.place_id;
                        axios
                          .get(
                            `https://us-central1-solar-campus-b542c.cloudfunctions.net/place_details?place=${place_id}`
                          )
                          .then((response) => {
                            console.log(response.data.result);
                            let result = response.data.result;
                            setValues({
                              ...values,
                              location: {
                                coords: result.geometry.location,
                                name: result.name,
                                viewport: result.geometry.viewport,
                              },
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      },
                    }}
                    apiKey="AIzaSyBudBqSdd0Plu0MAgdLhXMuYoOUM0Jl-40"
                  />
                  {props.editData !== null && (
                    <span style={{ fontWeight: "bold" }}>
                      Current Location: {props.editData.location.name}
                    </span>
                  )}
                  {locationError.status && (
                    <FormHelperText error={locationError.status}>
                      {locationError.message}
                    </FormHelperText>
                  )}
                </div>
                {/* </div> */}
                <FormControl
                  component="fieldset"
                  style={{ marginTop: "1.5em" }}
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    aria-label="status"
                    name="Status"
                    style={{ display: "flex", flexDirection: "row" }}
                    value={values.is_active}
                    onChange={handleChange("is_active")}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="InActive"
                    />
                  </RadioGroup>
                </FormControl>
              </form>
              <div className="sign-up-button-container">
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={loading}
                  onMouseDown={handleMouseDownPassword}
                  onClick={() => {
                    // dispatch(
                    //   update({
                    //     first_name: "Philemon",
                    //   })
                    // );
                    // console.log("pressed");
                    if (props.modal.for === "edit") {
                      if (
                        values.phone_number.length === 10 &&
                        values.title.length > 1 &&
                        values.description.length > 4 &&
                        values.category.length > 0 &&
                        values.campus.length > 0 &&
                        values.location.name.length > 0
                      ) {
                        errors();
                        // console.log("i ran");
                      } else {
                        errors();
                      }
                    } else {
                      if (
                        values.phone_number.length === 10 &&
                        values.title.length > 1 &&
                        values.description.length > 4 &&
                        values.category.length > 0 &&
                        values.campus.length > 0 &&
                        values.location.name.length > 0
                      ) {
                        setloading(true);
                        createShop();
                        errors();
                        // console.log("i ran");
                      } else {
                        errors();
                      }
                    }
                  }}
                >
                  {!loading ? (
                    props.modal.for === "edit" ? (
                      "UPDATE"
                    ) : (
                      "ADD"
                    )
                  ) : (
                    <CircularProgress
                      size={24}
                      color={"secondary"}
                      // style={{ color: "white" }}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}
