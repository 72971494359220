import { createSlice } from "@reduxjs/toolkit";

export const shopsSlice = createSlice({
  name: "shops",
  initialState: {
    shops: [],
    owner: null,
    selectedShop: null,
  },

  reducers: {
    updateShops: (state, action) => {
      console.log(action.payload, "shop payload in slice");
      //   console.log(action.payload.first_name, "first name");
      state.shops = action.payload;
    },
    updateOwner: (state, action) => {
      state.owner = action.payload;
    },
    updateSelectedShop: (state, action) => {
      state.selectedShop = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateShops, updateOwner, updateSelectedShop } =
  shopsSlice.actions;
export const shops = (state) => state.shops;
export default shopsSlice.reducer;
