import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Snackbar,
  Backdrop,
  IconButton,
  Icon,
  FormHelperText,
  Button,
  CircularProgress,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { db, storage } from "../../constants/firestore";
import { userData } from "../../redux/reducers/user/slice";

import { users } from "../../redux/reducers/users/slice";
import { categories } from "../../redux/reducers/categories/slice";
import AddCategory from "./addcategory";
// import { Link } from "react-router-dom";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Category() {
  const history = useHistory();
  const user = useSelector(userData);
  const categoriesList = useSelector(categories);
  const [page, setpage] = useState("feed");
  const [values, setValues] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    is_active: "",
    roles: "",
    id: "",
  });
  const [selectedDate, setSelectedDate] = useState(null);

  const [loading, setloading] = useState(false);
  const [openSnack, setOpenSnack] = useState({
    show: false,
    message: "",
    severity: "",
  });

  const [usernameStatus, setusernameStatus] = useState({
    status: false,
    show: false,
  });
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack({
      message: "",
      show: false,
      severity: "",
    });
  };
  const [visibility, setVisibility] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [modal, setmodal] = useState({ show: false, for: "" });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });

    // console.log(values);
  };

  const handleClickShowPassword = (prop, state) => {
    setVisibility({ ...visibility, [prop]: state });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [categoriesDataList, setcategoriesDataList] = useState([]);
  useEffect(() => {
    setcategoriesDataList(categoriesList.categories);
    if (user.user !== null) {
      let userDataVal = user.user;
      // console.log(userDataVal.first_name, "userDataval");
      setValues({
        full_name: userDataVal.first_name,
        is_active: userDataVal.is_active,
        email: userDataVal.email,
        roles: userDataVal.roles,
        id: userDataVal.id,
        created_at: userDataVal.created_at,
      });
    } else {
      history.push("/");
    }
  }, [modal, loading]);

  const handleCloseModal = () => {
    setmodal({ show: false, for: "" });
  };
  const handleModalToggle = (f) => {
    setmodal({
      show: !modal.show,
      for: f,
    });
  };
  const [loadingImage, setloadingImage] = useState(false);

  const updateUser = () => {
    let userDataVal = user.user;
    if (
      userDataVal.first_name !== values.first_name ||
      userDataVal.last_name !== values.last_name ||
      userDataVal.profession !== values.profession ||
      userDataVal.gender !== values.gender ||
      (values.short_background !== null &&
        values.short_background !== undefined &&
        values.short_background.length > 10 &&
        values.short_background !== userDataVal.short_background)
    ) {
      db.collection("users")
        .doc(userDataVal.id)
        .update({
          first_name: values.first_name.trim(),
          last_name: values.last_name.trim(),
          gender: values.gender,
          profession: values.profession.trim(),
          short_background:
            values.short_background !== undefined
              ? values.short_background
              : "",
          dob:
            selectedDate.getFullYear() - new Date().getFullYear() > 15
              ? selectedDate
              : null,
        })
        .then(() => {
          setloading(false);

          setOpenSnack({
            message: "Profile update successfully",
            severity: "success",
            show: true,
          });
        })
        .catch((error) => {
          setloading(false);

          setOpenSnack({
            message: error,
            severity: "error",
            show: true,
          });
        });
    } else {
      setloading(false);
      setOpenSnack({
        message: "No changes in Profile data, make changes to update",
        severity: "error",
        show: true,
      });
    }
  };
  const [deleteDialog, setdeleteDialog] = useState(true);
  const [selectedCategory, setselectedCategory] = useState(null);
  return (
    <div style={{ maxWidth: "100%" }}>
      <Snackbar
        style={{ zIndex: 99999999 }}
        open={openSnack.show}
        autoHideDuration={2500}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity={openSnack.severity}>
          {openSnack.message}
        </Alert>
      </Snackbar>
      <AddCategory
        category={selectedCategory}
        modal={modal}
        close={handleCloseModal}
      />
      <MaterialTable
        actions={[
          {
            isFreeAction: true,
            icon: "add",
            tooltip: "Add User",
            onClick: (event, rowData) => {
              // Do save operation
              setmodal({
                for: "user",
                show: true,
              });
            },
          },
          {
            icon: "edit",
            tooltip: "Edit User",
            iconProps: {
              color: "primary",
            },
            onClick: (event, rowData) => {
              // Do save operation
              setselectedCategory(rowData);
              setmodal({
                show: true,
                for: "edit",
              });
            },
          },
        ]}
        onRowClick={(event, rowData) => {}}
        columns={[
          { title: "Title", field: "title" },
          // { title: "Phone Number", field: "phone_number" },
          {
            title: "Types",
            field: "type",
            type: "string",
            render: (rowData) => {
              return (
                <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                  {rowData.type.map((it, i) => {
                    return (
                      <span
                        style={{
                          backgroundColor: "green",
                          color: "#fff",
                          padding: 5,
                          borderRadius: 5,
                        }}
                      >
                        {it}
                      </span>
                    );
                  })}
                </div>
              );
            },
          },
          {
            title: "Active",
            field: "is_active",
            cellStyle: (data, rowData) => ({
              color: data ? "green" : "red",
              justifyContent: "center",
              // display: "flex",
              // textAlign: "center",
              // color: "white",
              fontWeight: "bold",
            }),
          },
        ]}
        data={
          Array.isArray(categoriesDataList) &&
          categoriesDataList !== undefined &&
          categoriesDataList !== null &&
          categoriesDataList.length > 0
            ? categoriesDataList.map((d) => ({ ...d }))
            : []
        }
        title="Categories"
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
}
