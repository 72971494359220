import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  CircularProgress,
  FormHelperText,
  Snackbar,
  Backdrop,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Chip,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Link, useHistory } from "react-router-dom";
// import "./signup.css";
import { difference } from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { auth, db, fieldValue, storage } from "../../constants/firestore";
import { useSelector, useDispatch } from "react-redux";
import { update, userData } from "../../redux/reducers/user/slice";
import { categories } from "../../redux/reducers/categories/slice";
import { campus } from "../../redux/reducers/campus/slice";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import { shops } from "../../redux/reducers/shops/slice";
import { useFilePicker } from "use-file-picker";
export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddProduct(props) {
  const history = useHistory();
  const user = useSelector(userData);
  const categoriesList = useSelector(categories);
  const campusList = useSelector(campus);
  const [campusData, setcampusData] = useState([]);
  const [categoriesData, setcategoriesData] = useState([]);
  const shopsState = useSelector(shops);
  const selectedShop = useSelector(shops).selectedShop;
  const [owner, setowner] = useState({
    id: "",
  });

  useEffect(() => {
    console.log(user, "owners page");
    if (shopsState.shops !== null) {
      setowner({ ...shopsState.owner });
      console.log(shopsState.owner, "owner");
    }
  }, [shopsState]);
  useEffect(() => {
    console.log(campusList, "add campus");
    if (campusList.campus !== null) {
      console.log("this runs campus");
      setcampusData(campusList.campus);
    }
  }, [campusList]);
  useEffect(() => {
    console.log(categoriesList, "add shop categories");
    if (categoriesList.categories !== null) {
      console.log("this runs");
      setcategoriesData(categoriesList.categories);
    }
  }, [categoriesList]);
  const [modal, setmodal] = useState({ show: false, for: "" });
  const [values, setValues] = useState({
    title: "",
    price: "",
    description: "",
    is_active: "yes",
    stock: "",
    added_by: "",
    category: "",
    id: "",
    owner: "",
    shop: {
      id: "",
      description: "",
      title: "",
      location: {
        coords: {
          lat: null,
          lng: null,
        },
        name: "",
        viewport: {
          northeast: {
            lat: null,
            lng: null,
          },
          southwest: {
            lat: null,
            lng: null,
          },
        },
      },
      campus: "",
    },
    images: [
      {
        file_name: "",
        storage_path: "",
        image_url: "",
      },
    ],
  });
  const [type, settype] = useState("SHOP");
  const reset = () => {
    clear();
    setValues({
      title: "",
      price: "",
      description: "",
      is_active: "yes",
      stock: "",
      added_by: "",
      category: "",
      id: "",
      owner: "",
      shop: {
        id: "",
        description: "",
        title: "",
        location: {
          coords: {
            lat: null,
            lng: null,
          },
          name: "",
          viewport: {
            northeast: {
              lat: null,
              lng: null,
            },
            southwest: {
              lat: null,
              lng: null,
            },
          },
        },
        campus: "",
      },
      images: [
        {
          file_name: "",
          storage_path: "",
          image_url: "",
        },
      ],
    });
  };
  const [titleError, settitleError] = useState({
    message: null,
    status: false,
  });
  const [locationError, setlocationError] = useState({
    message: null,
    status: false,
  });
  const [stockError, setstockError] = useState({
    message: null,
    status: false,
  });
  const [descriptionError, setdescriptionError] = useState({
    message: null,
    status: false,
  });
  const [categoryError, setcategoryError] = useState({
    message: null,
    status: false,
  });
  const [priceError, setpriceError] = useState({
    message: null,
    status: false,
  });

  const [loading, setloading] = useState(false);
  const [openSnack, setOpenSnack] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [usernameStatus, setusernameStatus] = useState({
    status: false,
    show: false,
  });
  const [emailStatus, setemailStatus] = useState({
    status: false,
    show: false,
  });
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack({
      message: "",
      show: false,
      severity: "",
    });
  };
  const [visibility, setVisibility] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleCloseModal = () => {
    setmodal({ show: false, for: "" });
  };
  const handleModalToggle = (f) => {
    setmodal({
      show: !modal.show,
      for: f,
    });
  };
  const handleChange = (prop) => (event) => {
    // if (prop === "email" && props.user === null && props.modal.for !== "edit") {
    //   checkforEmail(event.target.value);
    // }

    setValues({ ...values, [prop]: event.target.value });

    // console.log(values);
    // errors();
  };

  const handleClickShowPassword = (prop, state) => {
    setVisibility({ ...visibility, [prop]: state });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const errors = () => {
    settitleError({
      status: values.title.length < 2,
      message:
        values.title.length < 2 ? "Title must be 2 or more characters" : "",
    });
    setpriceError({
      status: Number(values.price) === 0,
      message:
        Number(values.price) === 0 ? "Price must be greater than zero" : "",
    });
    setcategoryError({
      message: values.category.length === 0 ? "Choose a category" : "",
      status: values.category.length === 0,
    });
    setstockError({
      message:
        Number(values.stock) === 0 ? "Stock must be greater than zero" : "",
      status: Number(values.stock) === 0,
    });
    setdescriptionError({
      status: values.description.length < 5,
      message:
        values.description.length < 5
          ? "Description must be 5 or more characters"
          : "",
    });
    setOpenSnack({
      message: "Provide an image for the Product",
      severity: "error",
      show: filesContent.length === 0,
    });
  };
  const [
    openFileSelector,
    { filesContent, clear, errors: imageError, plainFiles },
  ] = useFilePicker({
    readAs: "DataURL",
    accept: [".png", ".jpg", ".jpeg"],
    multiple: false,
    // limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 10,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
  });
  useEffect(() => {
    if (props.editData !== null && props.editData !== undefined) {
      setValues({
        ...props.editData,
        is_active: props.editData.is_active ? "yes" : "no",
      });
    }
  }, [props.editData]);
  const createProduct = () => {
    setloading(true);
    const date = new Date().getTime();
    const productDBRef = db.collection("products").doc();
    const productRef = storage
      .ref("products")
      .child(productDBRef.id)
      .child(date + plainFiles[0].name.match(/\.[0-9a-z]+$/i)[0]);
    const uploadTask = productRef.putString(
      filesContent[0].content,
      "data_url",
      {
        contentType: plainFiles[0].type,
      }
    );
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload  is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload  is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload  is running");
            break;
        }
      },
      (error) => {
        // set(false);
        setloading(false);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          productDBRef
            .set({
              title: values.title,
              price: Number(values.price),
              description: values.description,
              is_active: values.is_active === "yes" ? true : false,
              stock: Number(values.stock),
              added_by: user.user.id,
              category: values.category,
              id: productDBRef.id,
              shop: selectedShop.id,

              shopInfo: {
                id: selectedShop.id,
                description: selectedShop.description,
                title: selectedShop.title,
                location: selectedShop.location,
                campus: selectedShop.campus,
                category: selectedShop.category,
              },
              images: [
                {
                  file_name: plainFiles[0].name,
                  storage_path: productRef.fullPath,
                  image_url: downloadUrl,
                },
              ],
              created_at: date,
            })
            .then(() => {
              dispatch({
                type: "products",
              });
              setTimeout(() => {
                props.close();
                reset();
                setOpenSnack({
                  message: `Product uploaded successfully`,
                  severity: "success",
                  show: true,
                });
                setloading(false);
              }, 2500);
            })
            .catch((error) => {
              setOpenSnack({
                message: error,
                severity: "error",
                show: true,
              });
            });
        });
      }
    );
    // ...
  };
  const updateShop = () => {
    let isActive = values.is_active === "yes" ? true : false;
    // let dif = difference(props.user.roles, userRole);
    if (
      values.title !== props.editData.title ||
      values.phone_number !== props.user.phone_number ||
      isActive !== props.user.is_active
      // || dif.length > 0
    ) {
      let id = user.user.id;
      let shopRef = db.collection("shops").doc(props.user.id);
      setloading(true);
      shopRef
        .update({
          title: values.title,
          phone_number: values.phone_number,
          description: values.description,
          is_active: values.is_active === "yes" ? true : false,
          phone_number: values.phone_number,
          location: values.location,
          added_by: user.user.id,
          campus: values.campus,
          category: values.category,
          id: shopRef.id,
          owners: fieldValue.arrayUnion(owner.id),
          type: type,
          updated: fieldValue.arrayUnion({
            on: new Date().getTime(),
            by: id,
          }),
        })
        .then((docRef) => {
          setloading(false);
          setOpenSnack({
            message: "Account updated successfully",
            show: true,
            severity: "success",
          });
          props.close();
          reset();
          // console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          setloading(false);
          setOpenSnack({
            message: error,
            show: true,
            severity: "error",
          });
          console.error("Error updating document: ", error);
        });
    } else {
      setOpenSnack({
        message: "No Changes made yet, make changes to update user",
        show: true,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    console.log(props.user, "userDataval");
  }, [props.user]);
  const dispatch = useDispatch();
  return (
    <Backdrop
      style={{
        zIndex: 1202,
        display: "flex",
        flexDirection: "column",
      }}
      open={props.modal.show}
      // onClick={handleCloseModal}
    >
      <Snackbar
        open={openSnack.show}
        autoHideDuration={2500}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity={openSnack.severity}>
          {openSnack.message}
        </Alert>
      </Snackbar>
      <div className="add-user-container" style={{ overflowY: "auto" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",

            width: "100%",
          }}
        >
          <IconButton
            style={{ alignSelf: "flex-end", justifySelf: "flex-end" }}
            onClick={() => {
              props.close();
              // setemailStatus({
              //   show: false,
              //   status: false,
              // });
              // setemailError({
              //   message: "",
              //   status: false,
              // });
              reset();
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
        <h3 style={{ marginTop: 0 }}>
          {props.modal.for === "edit" ? "EDIT" : "ADD PRODUCT"}
        </h3>
        <div className="sign-up-container">
          <div className="sign-up-container-two">
            <div style={{ marginTop: 5, marginBottom: 20 }}>
              <form
                className="sign-up-form"
                style={{ gap: "1em" }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    // disabled={props.modal.for === "edit" ? true : false}
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    autoComplete="off"
                    error={titleError.status}
                    helperText={titleError.message}
                    value={values.title}
                    onChange={handleChange("title")}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Price"
                    inputProps={{
                      maxLength: 10,
                    }}
                    variant="outlined"
                    error={priceError.status}
                    helperText={priceError.message}
                    value={values.price}
                    onChange={handleChange("price")}
                  />
                </div>

                <TextField
                  id="outlined-basic"
                  label="Shop Description"
                  variant="outlined"
                  multiline
                  rows={4}
                  error={descriptionError.status}
                  value={values.description}
                  helperText={descriptionError.message}
                  onChange={handleChange("description")}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControl variant="outlined" style={{ width: "47%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.category}
                      error={categoryError.status}
                      onChange={handleChange("category")}
                      label="Role"
                      style={{ zIndex: 1203 }}
                    >
                      {categoriesData.map((item, index) => {
                        return (
                          <MenuItem key={item.title} value={item.title}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                    <MenuItem value={"OWNER"}>OWNER</MenuItem>
                    <MenuItem value={"USER"}>USER</MenuItem> */}
                    </Select>
                    {categoryError.status && (
                      <FormHelperText error={categoryError.status}>
                        {categoryError.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Stock"
                    inputProps={{
                      maxLength: 10,
                    }}
                    variant="outlined"
                    error={stockError.status}
                    helperText={stockError.message}
                    value={values.stock}
                    onChange={handleChange("stock")}
                  />
                </div>
                <div
                  onClick={() => openFileSelector()}
                  style={{
                    /* background-color: gray; */
                    border: " 1px dashed green",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <h4>Pick File</h4>
                </div>
                {/* <FormHelperText>Click above to upload file</FormHelperText> */}
                {imageError.length > 0 && (
                  <FormHelperText error>
                    {imageError[0].fileSizeTooSmall &&
                      "File size is too small!"}
                    {imageError[0].fileSizeToolarge &&
                      "File size is too large!"}
                    {imageError[0].readerError &&
                      "Problem occured while reading file!"}
                    {imageError[0].maxLimitExceeded && "Too many files"}
                    {imageError[0].minLimitNotReached && "Not enought files"}
                  </FormHelperText>
                )}

                {plainFiles.length > 0 &&
                  plainFiles[0].type.includes("image") &&
                  filesContent.length > 0 && (
                    <Accordion
                      className="upload-accordion"
                      style={{
                        width: "100%",
                        flexDirection: "column",
                        border: descriptionError.status
                          ? "1px solid red"
                          : "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Image Preview</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <img
                          style={{
                            border: "1px solid #000",
                            alignSelf: "center",
                          }}
                          width={200}
                          height={200}
                          src={filesContent[0].content}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                <FormControl
                  component="fieldset"
                  style={{ marginTop: "1.5em" }}
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    aria-label="status"
                    name="Status"
                    style={{ display: "flex", flexDirection: "row" }}
                    value={values.is_active}
                    onChange={handleChange("is_active")}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="InActive"
                    />
                  </RadioGroup>
                </FormControl>
              </form>
              <div className="sign-up-button-container">
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={loading}
                  onMouseDown={handleMouseDownPassword}
                  onClick={() => {
                    // dispatch(
                    //   update({
                    //     first_name: "Philemon",
                    //   })
                    // );
                    // console.log("pressed");
                    // if (props.modal.for === "edit") {
                    //   if (
                    //     values.phone_number.length === 10 &&
                    //     values.title.length > 1 &&
                    //     values.description.length > 4 &&
                    //     values.category.length > 0 &&
                    //     values.campus.length > 0 &&
                    //     values.location.name.length > 0
                    //   ) {
                    //     errors();
                    //     // console.log("i ran");
                    //   } else {
                    //     errors();
                    //   }
                    // } else {
                    if (
                      Number(values.price) > 0 &&
                      values.title.length > 1 &&
                      values.description.length > 4 &&
                      values.category.length > 0 &&
                      Number(values.stock) > 0 &&
                      filesContent.length > 0
                    ) {
                      setloading(true);
                      createProduct();
                      errors();
                      // console.log("i ran");
                    } else {
                      errors();
                    }
                    // }
                  }}
                >
                  {!loading ? (
                    props.modal.for === "edit" ? (
                      "UPDATE"
                    ) : (
                      "ADD"
                    )
                  ) : (
                    <CircularProgress
                      size={24}
                      color={"secondary"}
                      // style={{ color: "white" }}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}
