import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  CircularProgress,
  FormHelperText,
  Snackbar,
  Backdrop,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
// import "./signup.css";
import { difference } from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { auth, db, fieldValue } from "../../constants/firestore";
import { useSelector, useDispatch } from "react-redux";
import { update, userData } from "../../redux/reducers/user/slice";
import axios from "axios";
export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddUser(props) {
  const history = useHistory();
  const user = useSelector(userData);
  const [modal, setmodal] = useState({ show: false, for: "" });
  const [values, setValues] = useState({
    full_name: "",
    // last_name: "",
    phone_number: "",
    email: "",
    roles: "",
    password: "",
    confirm: "",
    is_active: "yes",
  });
  const reset = () => {
    setValues({
      confirm: "",
      email: "",
      full_name: "",
      is_active: "yes",
      password: "",
      phone_number: "",
      roles: "",
    });
  };
  const [full_nameError, setfull_nameError] = useState({
    message: null,
    status: false,
  });
  const [emailError, setemailError] = useState({
    message: null,
    status: false,
  });
  const [phone_numberError, setphone_numberError] = useState({
    message: null,
    status: false,
  });
  const [roleError, setroleError] = useState({
    message: null,
    status: false,
  });
  const [passwordError, setpasswordError] = useState({
    message: null,
    status: false,
  });
  const [confirmPasswordError, setconfirmPasswordError] = useState({
    message: null,
    status: false,
  });

  const [loading, setloading] = useState(false);
  const [openSnack, setOpenSnack] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const [usernameStatus, setusernameStatus] = useState({
    status: false,
    show: false,
  });
  const [emailStatus, setemailStatus] = useState({
    status: false,
    show: false,
  });
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack({
      message: "",
      show: false,
      severity: "",
    });
  };
  const [visibility, setVisibility] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleCloseModal = () => {
    setmodal({ show: false, for: "" });
  };
  const handleModalToggle = (f) => {
    setmodal({
      show: !modal.show,
      for: f,
    });
  };
  const handleChange = (prop) => (event) => {
    if (prop === "email" && props.user === null && props.modal.for !== "edit") {
      checkforEmail(event.target.value);
    }

    setValues({ ...values, [prop]: event.target.value });

    // console.log(values);
  };

  const handleClickShowPassword = (prop, state) => {
    setVisibility({ ...visibility, [prop]: state });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const errors = () => {
    setfull_nameError({
      status: values.full_name.length === 0,
      message:
        values.full_name.length === 0
          ? "First name must be 2 or more characters"
          : "",
    });
    setphone_numberError({
      status: values.phone_number.length === 0,
      message:
        values.phone_number.length === 0
          ? "Last name must be 2 or more characters"
          : "",
    });
    setroleError({
      message: values.roles.length === 0 ? "Choose a role" : "",
      status: values.roles.length === 0,
    });

    setemailError({
      status:
        values.email.length < 7 ||
        !values.email.includes(".") ||
        !values.email.includes("@") ||
        emailStatus.status,
      message:
        values.email.length < 7
          ? "Email must be more than 6 characters"
          : !values.email.includes(".")
          ? "Email must contain (.)"
          : !values.email.includes("@")
          ? "Email must contain (@)"
          : emailStatus.status
          ? "Email is taken"
          : "",
    });
    if (props.modal.for !== "edit") {
      setpasswordError({
        status:
          values.password.length < 8 || values.password !== values.confirm,

        message:
          values.password.length < 8
            ? "Password must be 8 or more characters"
            : values.password !== values.confirm
            ? "Password and Confirm password must be eqaul"
            : "",
      });
      setconfirmPasswordError({
        status: values.confirm.length < 8 || values.password !== values.confirm,
        message:
          values.confirm.length < 8
            ? "Confirm Password must be 8 or more characters"
            : values.password !== values.confirm
            ? "Password and Confirm password must be eqaul"
            : "",
      });
    }
  };
  const checkforEmail = (email) => {
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setemailStatus({
            show: true,
            status: false,
          });
          setemailError({
            message: "",
            status: false,
          });
        } else {
          setemailStatus({
            show: true,
            status: true,
          });
          setemailError({
            message: "Email is taken",
            status: true,
          });
        }
      });
  };

  const createAccount = () => {
    setloading(true);
    axios
      .post(
        `https://us-central1-solar-campus-b542c.cloudfunctions.net/createUser?email=${values.email
          .trim()
          .toLowerCase()}&&password=${values.password}`,
        {
          full_name: values.full_name.trim(),
          roles:
            values.roles === "ADMIN"
              ? ["ADMIN", "USER"]
              : values.roles == "OWNER"
              ? ["USER", "OWNER"]
              : ["USER"],
          email: values.email.toLowerCase().trim(),
          created_at: new Date().getTime(),
          is_active: values.is_active === "yes" ? true : false,
          phone_number: values.phone_number,
          added_by: user.user.id,
        }
      )
      .then((response) => {
        console.log(response.data);
        setloading(false);
        if (response.data.status === "success") {
          props.close();
          reset();
        }

        setOpenSnack({
          message:
            response.data.status === "success"
              ? "Account created successfully"
              : "An error occurred, try again",
          show: true,
          severity: response.data.status,
        });
      })
      .catch((error) => {
        console.log(error.response);
        setloading(false);
        setOpenSnack({
          message: String(error.response) + "An error occurred, try again",
          show: true,
          severity: "error",
        });
      });
    // auth
    //   .createUserWithEmailAndPassword(values.email.trim(), values.password)
    //   .then((userCredential) => {
    //     // Signed in
    //     var userCred = userCredential.user.uid;
    //     let userRef = db.collection("users").doc(userCred);

    //     userRef
    //       .set({
    // id: userCred,
    // full_name: values.full_name.trim(),
    // roles:
    //   values.roles === "ADMIN"
    //     ? ["ADMIN", "USER"]
    //     : values.roles == "OWNER"
    //     ? ["USER", "OWNER"]
    //     : ["USER"],
    // email: values.email,
    // created_at: new Date().getTime(),
    // is_active: values.is_active === "yes" ? true : false,
    // phone_number: values.phone_number,
    // added_by: user.user.id,
    //       })
    //       .then((docRef) => {
    //         auth.signInWithEmailAndPassword(
    //           user.user.email,
    //           user.user.password
    //         );
    //         // user.currentUser
    //         //   .reauthenticateWithCredential(user.userCredential)
    //         //   .then(() => {
    //         //     // User re-authenticated.
    //         //     //  const currentUser = firebase.auth().currentUser();
    //         //     //  dispatch(updateUserCred(userCredential));
    //         //     //  dispatch(updateCurrentUser(currentUser));
    //         //   })
    //         //   .catch((error) => {
    //         //     alert(String(error));
    //         //     // An error ocurred
    //         //     // ...
    //         //   });
    // setloading(false);
    // props.close();
    // reset();
    // setOpenSnack({
    //   message: "Account created successfully",
    //   show: true,
    //   severity: "success",
    // });
    //         // console.log("Document written with ID: ", docRef.id);
    //       })
    //       .catch((error) => {
    //         setloading(false);
    //         setOpenSnack({
    //           message: String(error),
    //           show: true,
    //           severity: "error",
    //         });
    //         console.error("Error adding document: ", error);
    //       });
    //     // ...
    //   })
    //   .catch((error) => {
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     setloading(false);
    //     setOpenSnack({
    //       message: errorMessage,
    //       show: true,
    //       severity: "error",
    //     });
    //     console.log(error);
    //     // ..
    //   });
  };
  const updateUser = () => {
    let userRole =
      values.roles === "ADMIN"
        ? ["ADMIN", "USER"]
        : values.roles == "OWNER"
        ? ["USER", "OWNER"]
        : ["USER"];
    let isActive = values.is_active === "yes" ? true : false;
    let dif = difference(props.user.roles, userRole);
    // console.log(
    //   //   values.full_name !== props.user.full_name,
    //   //   userRole !== props.user.roles,
    //   //   values.phone_number !== props.user.phone_number,
    //   //   isActive !== props.user.is_active,
    //   //   props.user.roles,
    //   //   userRole,
    //   dif
    // );

    if (
      values.full_name !== props.user.full_name ||
      values.phone_number !== props.user.phone_number ||
      isActive !== props.user.is_active ||
      dif.length > 0
    ) {
      let id = user.user.id;
      let userRef = db.collection("users").doc(props.user.id);
      setloading(true);
      userRef
        .update({
          full_name: values.full_name.trim(),
          roles: userRole,
          is_active: isActive,
          phone_number: values.phone_number,
          updated: fieldValue.arrayUnion({
            on: new Date().getTime(),
            by: id,
          }),
        })
        .then((docRef) => {
          setloading(false);
          setOpenSnack({
            message: "Account update successfully",
            show: true,
            severity: "success",
          });
          props.close();
          reset();
          // console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          setloading(false);
          setOpenSnack({
            message: error,
            show: true,
            severity: "error",
          });
          console.error("Error updating document: ", error);
        });
    } else {
      setOpenSnack({
        message: "No Changes made yet, make changes to update user",
        show: true,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    console.log(props.user, "userDataval");

    if (props.user !== null) {
      let userDataVal = props.user;
      setValues({
        full_name: userDataVal.full_name,
        is_active: userDataVal.is_active ? "yes" : "no",
        email: userDataVal.email,
        roles: userDataVal.roles.includes("ADMIN")
          ? "ADMIN"
          : userDataVal.roles.includes("OWNER")
          ? "OWNER"
          : "USER",
        phone_number: userDataVal.phone_number,
      });
    }
  }, [props.user]);
  const dispatch = useDispatch();
  return (
    <Backdrop
      style={{
        zIndex: 1202,
        display: "flex",
        flexDirection: "column",
      }}
      open={props.modal.show}
      // onClick={handleCloseModal}
    >
      <Snackbar
        open={openSnack.show}
        autoHideDuration={2500}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity={openSnack.severity}>
          {openSnack.message}
        </Alert>
      </Snackbar>
      <div className="add-user-container">
        <div style={{ alignSelf: "flex-end" }}>
          <IconButton
            onClick={() => {
              props.close();
              setemailStatus({
                show: false,
                status: false,
              });
              setemailError({
                message: "",
                status: false,
              });
              reset();
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
        <h3 style={{ marginTop: 0 }}>
          {props.modal.for === "edit" ? "EDIT" : "USER"}
        </h3>
        <div className="sign-up-container">
          <div className="sign-up-container-two">
            <div style={{ marginTop: 5, marginBottom: 20 }}>
              <form className="sign-up-form" noValidate autoComplete="off">
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    error={full_nameError.status}
                    helperText={full_nameError.message}
                    value={values.full_name}
                    onChange={handleChange("full_name")}
                  />

                  <TextField
                    disabled={props.modal.for === "edit" ? true : false}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    autoComplete="off"
                    error={props.modal.for !== "edit" && emailError.status}
                    helperText={
                      props.modal.for !== "edit" ? emailError.message : ""
                    }
                    value={values.email}
                    onChange={handleChange("email")}
                    InputProps={{
                      autoComplete: "off",
                      endAdornment: props.modal.for !== "edit" &&
                        emailStatus.show && (
                          <InputAdornment position="end">
                            <Icon
                              style={{
                                color: emailStatus.status ? "red" : "green",
                              }}
                            >
                              {emailStatus.status ? "cancel" : "check_circle"}
                            </Icon>
                          </InputAdornment>
                        ),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    error={phone_numberError.status}
                    value={values.phone_number}
                    inputProps={{
                      maxLength: 10,
                    }}
                    type="number"
                    helperText={phone_numberError.message}
                    onChange={handleChange("phone_number")}
                  />
                  <FormControl variant="outlined" style={{ width: "47%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.roles}
                      error={roleError.status}
                      onChange={handleChange("roles")}
                      label="Role"
                      style={{ zIndex: 1203 }}
                    >
                      <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                      <MenuItem value={"OWNER"}>OWNER</MenuItem>
                      <MenuItem value={"USER"}>USER</MenuItem>
                    </Select>
                    {roleError.status && (
                      <FormHelperText error={roleError.status}>
                        {roleError.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                {props.modal.for !== "edit" && (
                  <TextField
                    style={{ marginBottom: 16 }}
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    error={passwordError.status}
                    helperText={passwordError.message}
                    onChange={handleChange("password")}
                    type={visibility.showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword(
                                "showPassword",
                                !visibility.showPassword
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            <Icon>
                              {visibility.showPassword
                                ? "visibility"
                                : "visibility_off"}
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                {props.modal.for !== "edit" && (
                  <TextField
                    id="outlined-basic"
                    label="Confirm Password"
                    variant="outlined"
                    error={confirmPasswordError.status}
                    helperText={confirmPasswordError.message}
                    onChange={handleChange("confirm")}
                    type={visibility.showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword(
                                "showConfirmPassword",
                                !visibility.showConfirmPassword
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            <Icon>
                              {visibility.showConfirmPassword
                                ? "visibility"
                                : "visibility_off"}
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <FormControl
                  component="fieldset"
                  style={{ marginTop: "1.5em" }}
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    aria-label="status"
                    name="Status"
                    style={{ display: "flex", flexDirection: "row" }}
                    value={values.is_active}
                    onChange={handleChange("is_active")}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="InActive"
                    />
                  </RadioGroup>
                </FormControl>
              </form>
              <div className="sign-up-button-container">
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={loading}
                  onMouseDown={handleMouseDownPassword}
                  onClick={() => {
                    // dispatch(
                    //   update({
                    //     first_name: "Philemon",
                    //   })
                    // );
                    // console.log("pressed");
                    if (props.modal.for === "edit") {
                      if (
                        values.phone_number.length === 10 &&
                        values.full_name.length > 1 &&
                        values.roles.length > 1
                      ) {
                        // console.log("updateUser");

                        updateUser();
                        errors();
                        // console.log("i ran");
                      } else {
                        errors();
                      }
                    } else {
                      if (
                        values.email.length > 6 &&
                        values.email.includes(".") &&
                        values.email.includes("@") &&
                        values.password.length >= 8 &&
                        values.password === values.confirm &&
                        values.confirm.length > 7 &&
                        values.phone_number.length === 10 &&
                        values.full_name.length > 1 &&
                        values.roles.length > 1
                      ) {
                        setloading(true);
                        createAccount();
                        errors();
                        // console.log("i ran");
                      } else {
                        errors();
                      }
                    }
                  }}
                >
                  {!loading ? (
                    props.modal.for === "edit" ? (
                      "UPDATE"
                    ) : (
                      "ADD"
                    )
                  ) : (
                    <CircularProgress
                      size={24}
                      color={"secondary"}
                      // style={{ color: "white" }}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}
