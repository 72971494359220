import { db } from "../../constants/firestore";
import { updatecampus } from "../reducers/campus/slice";

export const fetchCampus = (store) => (next) => (action) => {
  console.log("i ran, middleware campus", action);
  if (action.type === "campus") {
    db.collection("campuses").onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        let campus = [];
        snapshot.forEach((snap) => {
          campus.push(snap.data());
        });
        campus.sort();
        console.log(snapshot.size);
        store.dispatch(updatecampus(campus));
      } else {
        store.dispatch(updatecampus([]));
        console.log("categories does not exist");
      }
    });
  }
  return next(action);
};
