import { configureStore } from "@reduxjs/toolkit";
import { fetchCategories } from "../middlewares/categories";
import { fetchuser } from "../middlewares/user";
import { fetchUsers } from "../middlewares/users";
import userReducer from "../reducers/user/slice";
import usersReducer from "../reducers/users/slice";
import categoriesReducer from "../reducers/categories/slice";
import shopsReducer from "../reducers/shops/slice";
import campusReducer from "../reducers/campus/slice";
import { fetchShops } from "../middlewares/shops";
import { fetchCampus } from "../middlewares/campus";
export const store = configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    categories: categoriesReducer,
    shops: shopsReducer,
    campus: campusReducer,
  },
  middleware: [fetchuser, fetchUsers, fetchCategories, fetchShops, fetchCampus],
});
