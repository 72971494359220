import React, { useState, useEffect } from "react";
import { Icon, IconButton, Paper, Button } from "@material-ui/core";
import "./owner.css";
import { useDispatch, useSelector } from "react-redux";
import { shops, updateSelectedShop } from "../../redux/reducers/shops/slice";
import AddShop from "./addshop";
import { useHistory, useLocation } from "react-router-dom";
export default function Owner() {
  const shopsState = useSelector(shops);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [shopsDataList, setshopsDataList] = useState([]);
  const [owner, setowner] = useState({
    id: "",
  });
  const [modal, setmodal] = useState({ show: false, for: "" });
  const [editData, seteditData] = useState(null);

  useEffect(() => {
    console.log(shopsState, "owners page");
    if (shopsState.shops !== null) {
      setshopsDataList(shopsState.shops);
      setowner(shopsState.owner);
    }
  }, [shopsState]);

  return (
    <div className="owner-main-container">
      <AddShop
        editData={editData}
        modal={modal}
        close={() => {
          setmodal({
            for: "",
            show: false,
          });
        }}
      />
      <div style={{ alignSelf: "flex-end" }}>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          //   disabled={loading}
          //   onMouseDown={handleMouseDownPassword}
          onClick={() => {
            setmodal({
              for: "shop",
              show: true,
            });
          }}
        >
          Add Shop
          <Icon style={{ fontSize: 15 }}>add</Icon>
        </Button>
      </div>
      <div className="owner-items-container">
        {shopsDataList.map((val, index) => {
          return (
            <Paper key={val.title} elevation={3} className="owner-shop-card">
              <div className="owner-shop-card-overlay">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  //   disabled={loading}
                  //   onMouseDown={handleMouseDownPassword}
                  onClick={() => {
                    console.log(window.location.pathname, "path name");
                    history.push(window.location.pathname + "/shop/" + val.id);
                    dispatch(updateSelectedShop(val));
                  }}
                >
                  View Shop
                  <Icon style={{ fontSize: 15, color: "#fff" }}>
                    visibility
                  </Icon>
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ color: "#fff", borderColor: "#fff" }}
                  //   color="secondary"
                  //   disabled={loading}
                  //   onMouseDown={handleMouseDownPassword}
                  onClick={() => {
                    setmodal({
                      for: "edit",
                      show: true,
                    });
                    seteditData(val);
                  }}
                >
                  Edit Shop
                  <Icon style={{ fontSize: 15, color: "#fff" }}>edit</Icon>
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // gap: 20,
                  padding: "0.5em",
                }}
              >
                <h3 style={{ margin: 0, padding: 0 }}>{val.title}</h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "0.2em 1em 1em 1em",
                  gap: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                  >
                    Description
                  </span>
                  <span>{val.description}</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>Phone:</span>
                  <span style={{ fontWeight: "bold", width: "50%" }}>
                    {val.phone_number}
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>Campus:</span>
                  <span style={{ fontWeight: "bold", width: "50%" }}>
                    {val.campus}
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>Category</span>
                  <span style={{ fontWeight: "bold", width: "50%" }}>
                    {val.category}
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <span>Location:</span>
                  <span style={{ fontWeight: "bold", width: "50%" }}>
                    {val.location.name}
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>Status:</span>
                  <div style={{ width: "50%" }}>
                    <span
                      style={{
                        backgroundColor: val.is_active ? "green" : "red",
                        color: "white",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      {val.is_active ? "Active" : "Not Active"}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          );
        })}
      </div>
    </div>
  );
}
