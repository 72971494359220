import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
var firebaseConfig = {
  apiKey: "AIzaSyDdVYOnVoFhyJziRMSBwSUzUNlhPdnBQwQ",
  authDomain: "solar-campus-b542c.firebaseapp.com",
  projectId: "solar-campus-b542c",
  storageBucket: "solar-campus-b542c.appspot.com",
  messagingSenderId: "672032632816",
  appId: "1:672032632816:web:1b55c70362f8335d59f735",
  measurementId: "G-XGWF2NTNXT",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const fieldValue = firebase.firestore.FieldValue;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const db = firebase.firestore();
