import React from "react";

export default function ForgotpasswordImage() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="622.858"
        height="515.792"
        viewBox="0 0 622.858 515.792"
      >
        <g id="Group_36" data-name="Group 36" transform="translate(0 0)">
          <path
            id="Path_38"
            data-name="Path 38"
            d="M674.316,347.357c-3.419,29.294-10.524,52.813-20.275,71.57-12.976,24.992-35.172,35.062-55,45.6a125.4,125.4,0,0,1-17.816,7.747c-36.08,12.522-76.462,9.2-108.183,2.589a426.453,426.453,0,0,0-67.535-8.538q-9.713-.438-19.445-.435c-10,.013-190.147,3.432-234.006-85.364-10.829-21.9-18.251-48.661-20.918-81.276-7.546-92.209,33.926-152.769,87.421-192.353,101.195-74.879,239.9-71.739,339.607,5.113C624.579,163.2,686.76,240.673,674.316,347.357Z"
            transform="translate(-123.6 -52.509)"
            fill="#f2f2f2"
          />
          <path
            id="Path_39"
            data-name="Path 39"
            d="M314.643,375.664c0,73.294-43.572,98.886-97.322,98.886S120,448.958,120,375.664s97.322-166.537,97.322-166.537S314.643,302.369,314.643,375.664Z"
            transform="translate(-120 -107.512)"
            fill="#6c63ff"
          />
          <path
            id="Path_40"
            data-name="Path 40"
            d="M123.363,410.828l1-61.341L165.841,273.6l-41.325,66.264.448-27.581,28.589-54.9-28.47,47.6h0l.806-49.606,30.613-43.71-30.487,35.91.5-90.96-3.164,120.415.26-4.967L92.49,224.424,123.116,281.6l-2.9,55.4-.086-1.471L84.248,285.4l35.772,55.331-.363,6.929-.065.1.03.569-7.358,140.559h9.83l1.18-72.6,35.685-55.195Z"
            transform="translate(-29.587 -55.003)"
            fill="#3f3d56"
          />
          <path
            id="Path_41"
            data-name="Path 41"
            d="M974.643,382.664c0,73.294-43.572,98.886-97.322,98.886S780,455.958,780,382.664s97.322-166.537,97.322-166.537S974.643,309.369,974.643,382.664Z"
            transform="translate(-351.785 -109.97)"
            fill="#6c63ff"
          />
          <path
            id="Path_42"
            data-name="Path 42"
            d="M783.363,417.828l1-61.341L825.841,280.6l-41.325,66.264.448-27.581,28.589-54.9-28.47,47.6h0l.806-49.606,30.613-43.71-30.487,35.91.5-90.96-3.164,120.415.26-4.967L752.49,231.424,783.116,288.6l-2.9,55.4-.086-1.471L744.248,292.4l35.772,55.331-.363,6.929-.065.1.03.569-7.358,140.559h9.83l1.18-72.6,35.685-55.195Z"
            transform="translate(-261.372 -57.461)"
            fill="#3f3d56"
          />
          <path
            id="Path_43"
            data-name="Path 43"
            d="M652.81,278.308c0,80.135-47.639,108.115-106.4,108.115S440,358.443,440,278.308,546.4,96.228,546.4,96.228,652.81,198.172,652.81,278.308Z"
            transform="translate(-232.381 -67.863)"
            fill="#6c63ff"
          />
          <path
            id="Path_44"
            data-name="Path 44"
            d="M454.877,321.656l1.089-67.066,45.353-82.97-45.181,72.449.49-30.155,31.256-60.028-31.127,52.048h0l.881-54.236,33.471-47.79-33.332,39.261.55-99.45-3.459,131.653.284-5.431-34.029-52.088,33.484,62.514-3.171,60.573-.094-1.608-39.23-54.814,39.112,60.494-.4,7.575-.071.114.032.622L442.743,407h10.748l1.289-79.377L493.8,267.276Z"
            transform="translate(-144.729 -15.354)"
            fill="#3f3d56"
          />
          <path
            id="Path_45"
            data-name="Path 45"
            d="M679.477,708.289c0,17.2-112.707,70.72-251.738,70.72S176,725.489,176,708.289s103.624-55.8,242.655-55.8S679.477,691.089,679.477,708.289Z"
            transform="translate(-139.667 -263.217)"
            fill="#3f3d56"
          />
          <path
            id="Path_46"
            data-name="Path 46"
            d="M679.477,708.289c0,17.2-112.707,70.72-251.738,70.72S176,725.489,176,708.289s103.624-55.8,242.655-55.8S679.477,691.089,679.477,708.289Z"
            transform="translate(-139.667 -263.217)"
            opacity="0.1"
          />
          <path
            id="Path_47"
            data-name="Path 47"
            d="M679.477,708.289c0,17.2-112.707,31.143-251.738,31.143S176,725.489,176,708.289s103.624-55.8,242.655-55.8S679.477,691.089,679.477,708.289Z"
            transform="translate(-139.667 -263.217)"
            fill="#3f3d56"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            d="M694.429,605.571c0,24.674,15.479,44.639,34.608,44.639"
            transform="translate(-321.734 -246.739)"
            fill="#3f3d56"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M747.769,649.939c0-24.951,17.274-45.141,38.62-45.141"
            transform="translate(-340.466 -246.467)"
            fill="#6c63ff"
          />
          <path
            id="Path_50"
            data-name="Path 50"
            d="M713.755,609.018c0,23.437,9.871,42.4,22.069,42.4"
            transform="translate(-328.521 -247.949)"
            fill="#6c63ff"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M747.769,643.152c0-31.882,19.966-57.68,44.639-57.68"
            transform="translate(-340.466 -239.68)"
            fill="#3f3d56"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M736.548,673.422s4.908-.151,6.387-1.2,7.55-2.311,7.917-.622,7.376,8.4,1.835,8.446-12.875-.863-14.351-1.762S736.548,673.422,736.548,673.422Z"
            transform="translate(-336.525 -269.636)"
            fill="#a8a8a8"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M752.785,680.887c-5.541.045-12.875-.863-14.351-1.762-1.124-.685-1.572-3.142-1.722-4.276l-.164.006s.311,3.958,1.787,4.858,8.81,1.807,14.351,1.762c1.6-.013,2.152-.582,2.122-1.425C754.585,680.559,753.976,680.877,752.785,680.887Z"
            transform="translate(-336.525 -271.068)"
            opacity="0.2"
          />
          <ellipse
            id="Ellipse_17"
            data-name="Ellipse 17"
            cx="85.643"
            cy="13.625"
            rx="85.643"
            ry="13.625"
            transform="translate(205.673 428.203)"
            opacity="0.1"
          />
          <rect
            id="Rectangle_24"
            data-name="Rectangle 24"
            width="8.435"
            height="19.464"
            transform="translate(320.512 411.333)"
            fill="#ffb9b9"
          />
          <rect
            id="Rectangle_25"
            data-name="Rectangle 25"
            width="32.441"
            height="58.393"
            transform="translate(297.155 148.565)"
            fill="#ff6584"
          />
          <path
            id="Path_54"
            data-name="Path 54"
            d="M527.3,399.312l1.122,8.983-29.567,8.982-3.743-16.467Z"
            transform="translate(-173.878 -140.234)"
            fill="#ffb9b9"
          />
          <path
            id="Path_55"
            data-name="Path 55"
            d="M591.557,317.089s-.749,6.737-2.246,6.737-6.737,2.62-6.737,2.62l-1.5,7.111,10.48,6.363,17.216-1.871,4.865-7.485-4.865-4.866s-4.117-8.982-2.62-10.854S591.557,317.089,591.557,317.089Z"
            transform="translate(-281.926 -144.467)"
            fill="#ffb9b9"
          />
          <path
            id="Path_56"
            data-name="Path 56"
            d="M591.557,317.089s-.749,6.737-2.246,6.737-6.737,2.62-6.737,2.62l-1.5,7.111,10.48,6.363,17.216-1.871,4.865-7.485-4.865-4.866s-4.117-8.982-2.62-10.854S591.557,317.089,591.557,317.089Z"
            transform="translate(-281.926 -144.467)"
            opacity="0.1"
          />
          <path
            id="Path_57"
            data-name="Path 57"
            d="M520.766,677.948S517.4,700.4,513.281,701.9,529,706.018,529,706.018l1.5-8.234s.374-14.971,1.871-17.216S520.766,677.948,520.766,677.948Z"
            transform="translate(-257.919 -272.157)"
            fill="#ffb9b9"
          />
          <path
            id="Path_58"
            data-name="Path 58"
            d="M562.138,470.268s54.269-9.731,54.643-11.6,11.228,14.222,4.491,45.661-23.579,105.544-23.579,105.544-17.216,5.24-16.842,1.123,5.988-75.6,5.988-75.6L583.1,505.074l-6.363,3.743-26.573,52.4L536.687,606.5s-17.216,2.994-14.6-3.368,11.228-55.766,11.228-55.766L556.9,477.752S556.149,470.268,562.138,470.268Z"
            transform="translate(-261.115 -195.096)"
            fill="#2f2e41"
          />
          <circle
            id="Ellipse_18"
            data-name="Ellipse 18"
            cx="15.719"
            cy="15.719"
            r="15.719"
            transform="translate(298.964 146.236)"
            fill="#ffb9b9"
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M612.893,332.413s-7.485,7.111-25.076,5.24,4.865,40.047,4.865,40.047l7.111,42.293s31.439-3.743,32.561-10.854S612.893,332.413,612.893,332.413Z"
            transform="translate(-281.929 -150.809)"
            fill="#f2f2f2"
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M595.271,368.271s-8.982-4.491-12.351-16.842-6.363-25.076-8.234-24.7c-1.58.316-16.234,5.968-20.983,9.748-.876.7-1.415,1.33-1.473,1.854-.374,3.368,13.474,37.053,13.474,37.053s10.854,24.327,6.363,32.187-4.865,17.965,3.368,17.216,35.555-2.246,31.813-23.2c0,0,7.111,14.6,26.947,4.117,0,0,11.977-1.871,11.6-5.988s-19.088-39.673-19.088-39.673-2.994-15.719,2.62-21.708L616.6,323.359s-2.994-4.491-5.988-2.246a77.276,77.276,0,0,1-10.854,6.363c-1.123.374-1.861-1.345-1.861-1.345a32.54,32.54,0,0,0-3,17.439C596.019,353.3,595.271,368.271,595.271,368.271Z"
            transform="translate(-271.792 -146.619)"
            fill="#2f2e41"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M509.054,703.488s-.836-2.62-5.658,0c0,0,1.541-8.234-3.7-8.234s-7.111,9.731-7.111,9.731-1.5,6.363-11.6,8.982-2.246,11.6,4.866,11.6,16.468-5.614,16.468-5.614,19.462.374,19.836-3.368-1.871-14.971-3.368-14.971-4.491,5.24-4.491,5.24Z"
            transform="translate(-245.083 -278.235)"
            fill="#2f2e41"
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M618.267,696.889s-5.24-5.614-8.234,3.743-5.24,13.1-3.368,14.6,3.368.749,2.62,3.743,2.246,9.357,8.982,9.357,8.608-4.865,8.234-8.234-3.368-7.485-3.368-7.485l-2.994-10.48Z"
            transform="translate(-290.671 -278.222)"
            fill="#2f2e41"
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M653.205,292.033l-19.836,6.737s-17.965,10.48-10.105,12.725,14.6-6.737,14.6-6.737l17.216-4.491Z"
            transform="translate(-296.042 -136.628)"
            fill="#ffb9b9"
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M580.963,262.8a29.022,29.022,0,0,0-6.013,6.053c-3.622,4.531-7.171,9.942-6.278,15.673.339,2.177,1.306,4.2,1.851,6.338a33.39,33.39,0,0,1,.634,8.869,11.977,11.977,0,0,1-.464,3.74,24.653,24.653,0,0,1-1.844,3.409c-1.8,3.357-1.847,7.347-1.848,11.157a9.516,9.516,0,0,0,.513,3.784,15.051,15.051,0,0,0,2.168,3.033,13.083,13.083,0,0,1,2.017,11.853,5.135,5.135,0,0,0,4.665-2.941,6.5,6.5,0,0,1-1.663,5.289c4.284-1.273,8.877-3.42,10.441-7.607a16.121,16.121,0,0,0,.769-4.4c.832-10.5,1.307-21.4-2.46-31.237-1.205-3.148-2.845-6.2-3.249-9.541s.831-7.214,3.905-8.6a3.545,3.545,0,0,0,1.071,2.255c.684.508,1.95.189,1.962-.663l1.485,2.068a3.628,3.628,0,0,0,1.41-1.23l1.018,1.246,2.165-1.575,1.88,2.028a4,4,0,0,0,1.651-1.8c.4.6.9,1.269,1.628,1.334,1.577.142,1.9-2.36,3.279-3.14a2.325,2.325,0,0,1,2.9.879,4.736,4.736,0,0,1,.584,3.207c-.57,5.146-4.961,9.842-3.659,14.854.732,2.818,3.2,5.078,3.384,7.983.149,2.322-1.216,4.519-1.2,6.846.024,2.727,1.908,5.043,3.748,7.056q4.91,5.375,10.336,10.246l-1.117-1.955a3.629,3.629,0,0,1,2.846,1.047,4.92,4.92,0,0,0-.714-3.35c-3.009-6.319-5.438-13.046-5.8-20.036-.5-9.715,2.989-19.581.775-29.054a25.08,25.08,0,0,0-8.453-13.513c-2.047-1.685-3.6-2.695-6.251-2.881a47.183,47.183,0,0,0-8.9.07,20.384,20.384,0,0,0-9.17,3.2Z"
            transform="translate(-276.974 -125.152)"
            fill="#ff6584"
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M646.838,308.743a30.262,30.262,0,0,0,5.988,0,15.582,15.582,0,0,0,4.491-2.246l14.222-10.48-12.351,1.871s2.246-17.216,1.5-17.591,36.3-7.485,35.555,4.117-21.708,34.433-33.684,38.924l-7.86,1.123Z"
            transform="translate(-305.02 -131.629)"
            fill="#2f2e41"
          />
          <rect
            id="Rectangle_26"
            data-name="Rectangle 26"
            width="176.476"
            height="41.524"
            transform="translate(205.673 309.801)"
            fill="#fff"
          />
          <rect
            id="Rectangle_27"
            data-name="Rectangle 27"
            width="131.06"
            height="22.06"
            transform="translate(228.057 319.209)"
            fill="#3f3d56"
          />
          <path
            id="Path_66"
            data-name="Path 66"
            d="M509,556.451c-.052-.566-2.554-.817-5.987-.676,1.989-2.8,3.141-5.036,2.7-5.389-.42-.332-2.141,1.1-4.236,3.436-.2-3.129-.658-5.323-1.193-5.323-.584,0-1.079,2.61-1.241,6.2-2.915-2.1-5.269-3.327-5.632-2.868-.346.438,1.23,2.287,3.734,4.5-3.306.49-5.635,1.183-5.583,1.738s2.554.817,5.987.676c-1.989,2.8-3.141,5.036-2.7,5.388.42.332,2.141-1.1,4.236-3.436.2,3.129.658,5.323,1.194,5.323.585,0,1.079-2.61,1.241-6.2,2.915,2.1,5.269,3.327,5.632,2.868.346-.438-1.23-2.287-3.734-4.5C506.723,557.7,509.052,557.006,509,556.451Z"
            transform="translate(-250.487 -226.696)"
            fill="#fff"
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M543,556.451c-.052-.566-2.554-.817-5.987-.676,1.989-2.8,3.141-5.036,2.7-5.389-.42-.332-2.141,1.1-4.236,3.436-.2-3.129-.658-5.323-1.193-5.323-.585,0-1.079,2.61-1.241,6.2-2.915-2.1-5.269-3.327-5.632-2.868-.346.438,1.23,2.287,3.734,4.5-3.306.49-5.635,1.183-5.583,1.738s2.554.817,5.987.676c-1.989,2.8-3.141,5.036-2.7,5.388.42.332,2.141-1.1,4.236-3.436.2,3.129.658,5.323,1.194,5.323.585,0,1.079-2.61,1.241-6.2,2.915,2.1,5.269,3.327,5.632,2.868.346-.438-1.23-2.287-3.734-4.5C540.723,557.7,543.052,557.006,543,556.451Z"
            transform="translate(-262.427 -226.696)"
            fill="#fff"
          />
          <path
            id="Path_68"
            data-name="Path 68"
            d="M577,556.451c-.052-.566-2.554-.817-5.987-.676,1.989-2.8,3.141-5.036,2.7-5.389-.42-.332-2.141,1.1-4.236,3.436-.2-3.129-.658-5.323-1.193-5.323-.585,0-1.079,2.61-1.241,6.2-2.915-2.1-5.269-3.327-5.632-2.868-.346.438,1.23,2.287,3.734,4.5-3.306.49-5.635,1.183-5.583,1.738s2.554.817,5.987.676c-1.989,2.8-3.141,5.036-2.7,5.388.42.332,2.141-1.1,4.236-3.436.2,3.129.658,5.323,1.194,5.323.584,0,1.079-2.61,1.241-6.2,2.915,2.1,5.269,3.327,5.632,2.868.346-.438-1.23-2.287-3.734-4.5C574.723,557.7,577.052,557.006,577,556.451Z"
            transform="translate(-274.368 -226.696)"
            fill="#fff"
          />
          <path
            id="Path_69"
            data-name="Path 69"
            d="M611,556.451c-.052-.566-2.554-.817-5.987-.676,1.989-2.8,3.141-5.036,2.7-5.389-.421-.332-2.141,1.1-4.236,3.436-.2-3.129-.658-5.323-1.193-5.323-.584,0-1.078,2.61-1.241,6.2-2.915-2.1-5.269-3.327-5.632-2.868-.346.438,1.23,2.287,3.734,4.5-3.306.49-5.635,1.183-5.583,1.738s2.554.817,5.987.676c-1.989,2.8-3.141,5.036-2.7,5.388.42.332,2.141-1.1,4.236-3.436.2,3.129.658,5.323,1.194,5.323.585,0,1.079-2.61,1.241-6.2,2.915,2.1,5.269,3.327,5.632,2.868.346-.438-1.23-2.287-3.734-4.5C608.723,557.7,611.052,557.006,611,556.451Z"
            transform="translate(-286.308 -226.696)"
            fill="#fff"
          />
          <path
            id="Path_70"
            data-name="Path 70"
            d="M645,556.451c-.052-.566-2.554-.817-5.987-.676,1.989-2.8,3.141-5.036,2.7-5.389-.42-.332-2.141,1.1-4.236,3.436-.2-3.129-.658-5.323-1.193-5.323-.585,0-1.079,2.61-1.241,6.2-2.915-2.1-5.269-3.327-5.632-2.868-.346.438,1.23,2.287,3.734,4.5-3.306.49-5.635,1.183-5.583,1.738s2.554.817,5.987.676c-1.989,2.8-3.141,5.036-2.7,5.388.42.332,2.141-1.1,4.236-3.436.2,3.129.658,5.323,1.194,5.323.585,0,1.079-2.61,1.241-6.2,2.915,2.1,5.269,3.327,5.632,2.868.346-.438-1.23-2.287-3.734-4.5C642.723,557.7,645.052,557.006,645,556.451Z"
            transform="translate(-298.249 -226.696)"
            fill="#fff"
          />
          <path
            id="Path_71"
            data-name="Path 71"
            d="M548.685,501.37s-1.871,24.7,7.111,25.825.749-28.07.749-28.07Z"
            transform="translate(-270.493 -209.356)"
            fill="#ffb9b9"
          />
          <path
            id="Path_72"
            data-name="Path 72"
            d="M551.521,346.835l-2.62.749s-10.854,86.83-3.743,103.672c0,0,7.485-4.117,11.228-3.368l5.988-70.737Z"
            transform="translate(-268.463 -155.873)"
            fill="#2f2e41"
          />
        </g>
      </svg>
    </div>
  );
}
